const { DOM } = require("../ui");

export default ($) => {
  var owlCarousel = $(DOM.owlCarousel),
    owlItems = owlCarousel.attr("data-items"),
    owlCarouselSlider = $(DOM.owlCarouselSlider),
    owlNav = owlCarouselSlider.attr("data-nav");

  owlCarousel.owlCarousel({
    items: owlItems,
    // navigation: true,
    navigationText: ["", ""],
  });
  //   d("owl:", DOM);
  const timeOut = $('#banner').data('bannerTimeout') || 6000;
  // d('timeout:',timeOut);
  owlCarouselSlider.owlCarousel({
    slideSpeed: 300,
    paginationSpeed: 400,
    // pagination: owlSliderPagination,
    singleItem: true,
    navigation: owlNav ? true : false,
    navigationText: ["", ""],
    transitionStyle: "goDown",
    autoPlay: timeOut
  });
};
