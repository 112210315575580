export default $ => {
    // Initialize Select2
    $('select.select2').select2();
    // $('select.select2').show();
    $('.select2-placeholder').hide();

    // Copy button
    $(document).on( 'click', '.copy-button', function() {
        
        var $el = $(this).parent().find('input.code');
        $el.parent().find('.alert').remove();
        /* Get the text field */
        var copyText = $el[0];

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
        $el.show();
        $el.after('<div class="alert alert-">Copied to clipboard!</div>');
        copyText.blur();
        $(this).hide();
        setTimeout( function() {
            // $el.hide();
            $el.parent().find('.alert').remove();
        },1000);
    })
}