const {getLocalData} = require('../../utils/localData');
const {replaceArea} = require('../../ui');
const magnificPopups = require('../../components/magnificPopups').default;
const {DOM} = require('../../ui');
const {loadTemplates} = require("../../utils/loadData");

export default async $ => {// return;
    var user = getLocalData('user');
    var loaded = true;
    const searchData = await loadTemplates();
    // d('data',searchData);
    await replaceArea(DOM.topMenu, 'top-menu', { user, loaded:'abs', something:'else',collections: searchData })
// d('-- popups');
    magnificPopups($);
} 