const {DOM} = require('../ui');
const {getLocalData,setLocalData} = require('../utils/localData');
function updateStyle() {
    const $ = jQuery.noConflict();
    try {
        const style = JSON.parse(localStorage.getItem('style') || '{}');
        // if( !style || !style.length ) return;
        var css = '';
        $('.form_holder input[type=color]').each(function () {
            var i = $(this).attr('name').replace('color-', '');
            var color = style['color-' + i];
            if (!color) return;
            css += `.text-${i} { color: ${style['color-' + i]} !important }` +
                ` .border-${i},.btn-${i} { border-color: ${style['color-' + i]} !important }` +
                ` .bg-${i},.btn-${i}, .badge-${i} { background-color: ${style['color-' + i]} !important }`
                + ` .text-${i} ::placeholder { color: ${style['color-' + i]} !important }`
            $('input[name=color-' + i + ']').val(style['color-' + i]);
        });
        if( style['color-secondary'] ) css += `.nav-item.active{ background-color: ${style['color-secondary']}`;

        $('.form_holder input.bodyClass').each(function () {
            var name = $(this).attr('name');
            if (style[name]) {
                $(this).prop('checked', 'checked');
                $('body').addClass(name);
            } else {
                $(this).prop('checked', '');
                $('body').removeClass(name);
            }

        })
        // d('css:',css);
        $('#json-style').val(JSON.stringify(style));
        $('style.primary').remove();
        $('body').append('<style class="primary">' + css + '</style>');

        if (style['button-style'] ) {
            $('[name=button-style]').val(style['button-style']);
            $('.btn-outline-primary').removeClass('btn-outline-primary').addClass('btn-'+style['button-style']);
            $('.btn-secondary').removeClass('btn-secondary').addClass('btn-' + style['button-style']);
        }
        if (style.boxed) {
            var val = style.boxed;
            var $el = val == 'wide' ? $('#btn-wide') : $('#btn-boxed');
            $el.addClass('btn-primary');
            $el.siblings().removeClass('btn-primary');
            if (val == 'boxed') $('body').addClass('boxed');
            else $('body').removeClass('boxed');
        }
    } catch (e) {
        localStorage.setItem('style', '');
        d('error updating style:', e.message);
    }
}
const updateBoxStyle = () => {
    var style = JSON.parse(localStorage.getItem('style') || '{}');
    var name = 'boxed';
    var val = $(this).data('value');
    $(this).addClass('btn-primary');
    $(this).siblings().removeClass('btn-primary');
    style[name] = val;
    localStorage.setItem('style', JSON.stringify(style));
    updateStyle();
}

const updateJSONStyle = e => {
    setLocalData('style', $(e.target).val() );
    updateStyle();
}
const toggleSwitcher = e => {
    const $ = jQuery.noConflict();
    var $form = $(DOM.switcher.form);
    if ($form.hasClass('d-none')) $form.removeClass('d-none');
    else $form.addClass('d-none');
}

const clearStyle = e => {
    setLocalData('style','');
    updateStyle();
}

const updateInputs = e => {
    const $ = jQuery.noConflict();
    var style = getLocalData('style')||{};
    var name = $(e.target).attr('name');
    if ($(e.target).attr('type') == 'checkbox') {
        var val = $(e.target).is(':checked');
    } else {
        var val = $(e.target).val();
    }

    style[name] = val;
    setLocalData('style',style);
    updateStyle();
}
export default $ => {
    // Update Styles
    updateStyle();

    // Update JSON Style box
    $(document).on('change', DOM.switcher.jsonStyle, updateJSONStyle );
    $(document).on('click', DOM.switcher.boxStyle, updateBoxStyle)

    $(document).on('click', DOM.switcher.toggle, toggleSwitcher )
    $(document).on('click', DOM.switcher.clear, clearStyle )
    $(document).on('change', DOM.switcher.inputs, updateInputs );
    
    
}