const {prependArea} = require('../ui');
const { getLocalData, setLocalData } = require("../utils/localData");

export default ($) => {
    const user = getLocalData('user');
    if( user ) {
        // d('role:',user.role);
        if( user.role.type == 'administrator' ) {
            
            const page = $('.page').data('page');
            const layout = $('.layout').data('layout');
            const context = {
                url:window.location.href,
                page,
                layout
            }
            d('layout:',layout);
            prependArea( 'body', 'adminbar', context)
            d('-- add adminbar');
            $('body').addClass('adminbar');
        }
    }
}