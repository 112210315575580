export const getLocalData = (key) => {
  const str = localStorage.getItem(key);
  if (str)
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
};
export const setLocalData = (key, data) => {
  const value = typeof data == "object" ? JSON.stringify(data) : data;
  if (value == 'undefined') localStorage.setItem(key, '');
  else localStorage.setItem(key, value);
};
