const axios = require("axios");
const { getCookie, setCookie } = require('../utils/cookies');
let instance = null;
export const login = async function (params) {
  if (!params) params = {};
  let jwt = localStorage.getItem("jwt");
  const baseURL = jQuery('body').data('apiurl');
  d('baseURL:',baseURL);
  // d('params:',params);
  // d('looggin');
  //   d("jwt:", jwt);
  // d(params);
  // Reuse instance if possible
  if (params.public) {
    // d('-- not logged in', baseURL);
    instance = axios.create({ baseURL });
    return instance;
  }
  if (instance && !params.identifier && !params.force) {
    // d('-- use existing instance');
    // if (jwt) localStorage.setItem('jwt', jwt);
    return instance;
  }
  if (!params.public) {
    if (!jwt) jwt = getCookie("jwt");

    const { id_token, provider, access_token } = params;
    if (id_token) {
      const url =
        baseURL +
        "/auth/" +
        provider +
        "/callback?id_token=" +
        id_token +
        "&access_token=" +
        access_token;
      const data = [id_token, access_token];
      // d('url:', url, data);
      const res1 = await axios.get(url).catch((e) => {
        // d(e.message);
        return e.response;
      });
      if (res1.status == 200) {
        // d('google login:',res1.data);
        // if (res1.data.jwt) localStorage.setItem("jwt", res1.data.jwt);
        // setCookie("jwt", res1.data.jwt);
        instance = axios.create({ baseURL });
        instance.defaults.headers.common["Authorization"] = "BEARER " + jwt;
        return res1;
      } else {
        return res1;
      }
    }
    if (params.identifier) {
      const authData = await axios
        .post(baseURL + "/auth/local", {
          identifier: params.identifier,
          password: params.password,
        })
        .catch((e) => e.response);
        // d("auth:",authData);
      if (!authData) {
        // d('no autodata!');
        return;
      }
      // d('response:',authData);
      if (authData.status != 200) {
        return authData;
      }
      if (authData.data) {
        jwt = authData.data.jwt;
        localStorage.setItem('jwt',jwt);
        setCookie('jwt',jwt);
        
        instance = axios.create({ baseURL });
        instance.defaults.headers.common["Authorization"] = "BEARER " + jwt;
        return authData;
      } else {
        return { data: { error: "API down" } };
      }
    }
    if (jwt && jwt != null) {
      // d('jwt!',jwt);
      // setCookie('jwt', jwt, 1);
      instance = axios.create({ baseURL });
      instance.defaults.headers.common["Authorization"] = "BEARER " + jwt;

      return instance;
    }
  }
};
