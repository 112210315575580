
const config = require('../../_data/config');
var env = new nunjucks.Environment();
// async filters must be known at compile-time
env.addFilter("truncate", (str, length) => {
    // length = 20;
    if (length && str.length > length) {
        const index = str.indexOf(" ", length);
        const truncated = str.substring(0, index);
        if (!truncated) return str;
        return truncated + '<a href="#" class="tooltip-toggle" title="' + str + '">...</a>';
    }
    return str;
});

env.addFilter('url', url => {
    return url;
})
// Image Resize
env.addFilter('imageResize', (url, w, h) => {
    const apiURL = jQuery('body').data('apiurl');
    const route = apiURL + '/image?url=' + encodeURIComponent(url) + '&w=' + w + '&h=' + h;
    return route;
})

env.addFilter( 'label', (slug,type) => {
    const all = config[type];
    const label = Object.entries(all).find( ([index,value]) => {
      if( index == slug ) return value;
    })
    // d('label:',label);
    return label[1];
})
env.addFilter('stringify', obj => {
    // d('obj:', obj);
    return JSON.stringify(obj);
})
env.addFilter('badgeClass', function(score) {
    if( score > 6 ) return 'success';
    if (score > 4) return 'warning';
    else return 'danger';
})
env.addFilter(
    "getFieldById",
    function (id, field, items) {
        // d("items:", items);
        const item = items.find((m) => m.id == id) || {};
        // d('item:',item);
        return item[field];
    },
    true
);

env.addFilter("upTo", (count) => {
    const numbers = [];
    for (var i = 0; i < count; i++) {
        numbers.push(i);
    }
    // d('numbers:',numbers,'count:',count);
    return numbers;
});
env.addFilter("apiURL", function (url) {
    const apiURL = jQuery('body').data('apiurl');
    return apiURL + url;
});
env.addFilter("remaining", (date) => {
    const then = new Date(Date.parse(date + ' 11:59:59'));
    const now = new Date();
    var diff = Math.abs(then - now) / 1000; // delta
    var r = {}; // result
    var s = {
        // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400, // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1,
    };
    var labels = {
        year: "yr|yr",
        month: "mth|mth",
        week: "wk|wk",
        day: "day|days",
        hour: "h|h",
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(diff / s[key]);
        diff -= r[key] * s[key];
    });

    let str = "month week day remaining";
    // d('diff:',diff);
    // if( diff < 3600*24 ) return 'Last Day';
    if (r.year == 0 && r.month == 0 && r.week == 0 && r.day == 0) return 'Last Day';
    Object.entries(r).forEach(([key, value]) => {
        if (!labels[key]) return;
        const [single, plural] = labels[key].split("|");
        const use = value > 1 ? plural : single;
        // d('key:',key,'label:',single,plural,'use:',value,use);
        const replace = value > 0 ? value + "" + use : "";
        str = str.replace(key, replace);
    });
    // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
    // d('r:',r);
    // d(str);

    return str;
});

env.addFilter("getMerchantLink", (id, merchants) => {
    // d('merchants:',merchants.length,id);
    const merchant = merchants.find((m) => m.id == id) || {};
    // d('merchant:',merchant);
    return merchant.url
        ? '<i class="fa fa-globe mr-3"></i> <a href="' +
        merchant.url +
        '">' +
        merchant.website +
        "</a>"
        : merchant.website;
});

env.addFilter("readableDate", (date) => {
    if (typeof date == "string") date = new Date(Date.parse(date));
    let hr = date.getHours();
    const ap = hr >= 12 ? "pm" : "am";
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const m = date.getMonth();
    return months[m] + " " + date.getDate() + ", " + date.getFullYear();
});

env.addFilter("merchants", (id) => {
    // d("FIND MERCHANT:",merchants.length);
    if( window.searchData ) {
        const merchants = searchData.merchants;
        const merchant = merchants.find(m => parseInt(m.id) == parseInt(id));
        if (merchant) return merchant.merchant;
    }
    return '';
});

module.exports = env;