const { getCookie, setCookie } = require('../utils/cookies');

window.d = console.log;
function parseJSON(str) {
  if (typeof str == "string") {
    let data, debug;
    try {
      data = JSON.parse(str);
    } catch (e) {}
    str = ` ${str} `;

    if (!data) {
      try {
        const match = str.match(/\{(.*)\}/g);
        let data = match ? match.pop() : "";
        debug = match ? str.replace(data, "") : str + " ";
        data = JSON.parse(data);
        console.log("DEBUG:", debug);
        return { data, debug };
      } catch (e) {
        data = {};
      }
    }

    return { data, debug };
  } else return { data: str, debug: "" };
}
(function ($) {
  $.fn.addLoading = function (color = "primary", grow = false, append = false) {
    // d('messages',this.$messages,this.$form,html);
    if ($(this).find(".messages").length == 0)
      $(this).append('<div class="messages"></div>');
    const typeCls = grow ? "spinner-grow" : "spinner-border";
    const html = '<div class="text-' + color + " " + typeCls + '"></div>';
    if (append) $(this).find(".messages").append(html);
    else $(this).find(".messages").html(html);
  };
  $.fn.removeLoading = function () {
    $(this).find(".messages").html("");
  };
  $.fn.message = function (data) {
    let { status, message } = data;
    if ($(this).find(".messages").length == 0)
      $(this).append('<div class="messages"></div>');

    if (status == "error") status = "danger";
    // d('message', message, status, $(this).find('.messages'));
    $(this)
      .find(".messages")
      .html('<div class="alert alert-' + status + '">' + message + "</div>");
  };
  $.fn.errorMessage = function (message) {
    $(this).message({ status: "danger", message });
  };
  $.fn.successMessage = function (message) {
    $(this).message({ status: "success", message });
  };
  $.fn.warningMessage = function (message) {
    $(this).message({ status: "warning", message });
  };
  $.fn.validate = function (visible = ":visible") {
    halt = 0;
    $(this).find("*[data-condition-not] :input").trigger("validate");
    $(this)
      .find(":input" + visible)
      .each(function () {
        // Ignore disabled inputs
        if ($(this).attr("disabled")) return;
        var $field = $(this).closest(".form-group");
        $field.removeAttr("invalid");

        var name = $(this).attr("name");
        var value = $(this).val();

        if (
          $(this).attr("type") == "submit" ||
          $(this).attr("type") == "button"
        )
          return;
        if ($(this).attr("type") == "checkbox" && !$(this).is(":checked"))
          value = false;

        if ($(this).attr("type") == "radio") {
          if ($(this).attr("required") || $(this).attr("aria-required")) {
            if ($("input[name=" + name + "]:checked").length == 0) {
              // $(this).addClass('invalid');
              // $field.find('label').addClass('invalid');
              $field.attr("invalid", true);
              if (!halt) halt = 1;
            }
          }
        } else if (
          $(this).attr("type") == "checkbox" &&
          $(this).closest(".checkboxes").length > 0
        ) {
          if ($(this).attr("required") || $(this).attr("aria-required")) {
            // $field.find('input[type=checkbox]').addClass('invalid');
            // $field.find('> label').addClass('invalid');
            $field.attr("invalid", true);
            if (!halt) halt = 1;
          }
        } else if (
          value == "" ||
          ($(this).attr("type") == "checkbox" && !$(this).is(":checked"))
        ) {
          if ($(this).attr("required") || $(this).attr("aria-required")) {
            // $(this).addClass('invalid');
            // $field.addClass('invalid');
            // $field.find('> label').addClass('invalid');
            $field.attr("invalid", true);
            if (!halt) halt = 1;
          }
          //   d("INVLAID:", name, "vlaue:", value);
        }
      });

    $(this)
      .find("input[type=file]")
      .each(function () {
        if ($(this)[0].files.length == 0 && $(this).attr("aria-required")) {
          // $(this).addClass('invalid');
          // $(this).closest('.form-group').find('label').addClass('invalid');
          $field.attr("invalid", true);
          if (!halt) halt = 1;
          d(" file halt: " + halt);
        }
      });

    if ($(this).find("[invalid]").length > 0) halt = 1;
    // d('inavlidity:', halt );
    if (halt) return false;
    else return true;
  };

  $.fn.renderValidation = function () {
    var $form = $(this);
    $form.find(".invalid-error").remove();
    var valid = $form.validate();

    if (!valid) {
      // Fill in invalid messages
      $form.find("[invalid]").each(function () {
        var $field = $(this).closest(".form-group");
        d("field:", $field[0]);
        $field.addClass("invalid");

        var msg = $field.attr("data-invalid");
        d("message:", msg);
        if (msg && $field.find(".invalid-error").length == 0) {
          d("???appedn");
          $field.append('<div class="alert alert-danger">' + msg + "</div>");
        }
      });

      // Validator - allows for skipping required fields IF field provided by ".validator" has a note explaining

      // Focus on fisrt invalid field
      $form.find(".invalid").first().focus();

      // Add invalid message
      // Add form error message
      var message = $form.data("invalid");
      if (message) $form.errorMessage(message);

      // Trigger form complete event with invalid status
      $form.trigger("complete", { status: "error", message: message });
      return false;
    }
    return true;
  };

  $.fn.processResponseSimple = function (response, context) {
    // Parse JSON if applicable
    // d('RESPONSE:', response.form.values[0]);
    var $this = $(this);

    const status =
      response.statusCode == 200 || !response.statusCode ? "success" : "danger";
    let message = "";
    // d('response:',response);
    // d(response.statusCode);
    if (response.statusCode == 200 || !response.statusCode) {
      d("this:", $(this)[0]);
      message = $(this).data("success") || "Success";
      response.reload = $(this).data("reload");
      response.url = $(this).data("url");
    } else {
      if (typeof(response.message) == 'object' ) {
        message = response.message
          .map((m) => {
            // d('message:', message);
            const html = m.messages
              .map((message) => message.message)
              .join("<br/>");
            d("html:", html);
            return html;
          })
          .join("<br/>");
      } else {
        message = response.message;
      }
    }
    $(this).message({ status, message }, false);

    if (typeof response == "string") {
      var r = parseJSON(response);
      response = r.data;
    }
    if (typeof response == null || !response) return;
    // d('process',response);
    if (!context && response.context) context = response.context;
    if (!context) context = $(document);
    if (response.status == "OK") response.status = "success";
    // Get timeout time
    var timeout = $(this).data("timeout") ? $(this).ata("timeout") : 1500;

    // Arbitrary js
    if (response.pre_js) {
      eval(response.pre_js);
    }

    // removable blocks
    if (response.remove) {
      for (var i in response.remove) {
        var item = response.remove[i];
        // html/outerhtml
        d("REMOVE", item);
        $(item).remove();
      }
    }

    // Build DOM updates
    const updates = [response];

    // Use the main item

    // merge with updates
    if (response.updates)
      for (var update of response.updates) updates.push(update);
    // d('updates:',updates);
    for (var update of updates) {
      if (!update.skipmessages)
        var messages = $(update.selector, context).find(".messages").html();
      if (update.outerhtml) {
        $(update.selector, context).each(function () {
          $(this)[0].outerHTML = update.outerhtml;
        });
      }

      if (update.html) {
        const html = update.html;
        const html_timeout = update.message ? timeout : 0;
        setTimeout(() => {
          $(update.selector).html(html);
          $(update.selector).trigger("create");
        }, html_timeout);
      }
      if (update.append) {
        $(update.selector, context).append(update.append);
        $(update.selector, context).trigger("create");
      }
      if (update.prepend) {
        $(update.selector, context).prepend(update.prepend);
        $(update.selector, context).trigger("create");
      }
      if (update.after) {
        $(update.selector, context).after(update.after);
        $(update.selector, context).trigger("create");
      }
      if (update.before) {
        $(update.selector, context).before(update.before);
        $(update.selector, context).trigger("create");
      }

      // Add or remove classes
      if (update.removeClass) {
        d("remove class " + update.removeClass);
        $(update.selector).removeClass(update.removeClass);
      }
      if (update.addClass) {
        d("add class " + update.addClass);
        $(update.selector).addClass(update.addClass);
      }

      // Change value of a form input
      if (update.value) {
        $(update.selector, ctx).val(update.value);
      }
      // Change text
      if (update.text) {
        $(update.selector, ctx).text(update.text);
      }

      // Options on a select field
      if (update.options) {
        $(update.selector + ' option[value!=""]', ctx).remove();
        for (var i in update.options) {
          var val = update.options[i];
          $(update.selector).append(
            '<option value="' + i + '">' + val + "</option>"
          );
        }
        if (update.value) $(update.selector, ctx).val(update.value);
      }

      // Data attributes
      if (update.data) {
        for (var key in update.data) {
          var value = update.data[key];
          $(update.selector, context).data(key, value);
        }
      }

      // Click on a selector
      if (update.trigger) $(update.selector, ctx).trigger(update.trigger);
    }

    // reload if needed
    if (response.reload) {
      $(this).addLoading("success", false, true);
      setTimeout(function () {
        window.location.reload();
      }, timeout);
    }

    // redirect if needed
    if (response.url) {
      $(this).addLoading("success", false, true);
      setTimeout(function () {
        window.location.href = response.url;
      }, timeout);
    }
  };
})(jQuery);
jQuery(document).ready(function ($) {
  // d("Loaded WAF simple handler");
  $("form[data-handler=simple] [type=submit]").removeAttr("disabled");
  $(document).on("submit", "form[data-handler=simple]", ajaxSubmitForm);
  $(document).on("complete", "form[data-handler=simple]", ajaxCompleteForm);
  $(document).on("click", "[route]", ajaxSubmitButton);
  $(document).on("keyup", ".form-group.invalid :input", clearInvalid);
  $("form.waf [type=submit]").removeAttr("disabled");
});
/* Simplified Form Submit Handler */
function ajaxSubmitButton(e) {
  const $ = jQuery.noConflict();
  e.preventDefault();
  const $form = $(this);

  // Prepare Data
  const method = $(this).attr("method") || "get";
  const url = $(this).attr("route");
  var data = {};
  for (var k in $(this).data()) {
    if (k == "method" || k == "route") continue;
    if (typeof $(this).data(k) != "object") {
      data[k] = $(this).data(k);
      if (k == "user" && data[k] == "me") {
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          if (user) data[k] = user.id;
        } catch (e) {}
      }
    }
  }
  d("data:", data);

  // Submit
  const headers = {};
  if (!data.public) {
    let jwt = localStorage.getItem("jwt");
    if( !jwt ) jwt = getCookie('jwt');
    if (jwt) {
      headers["Authorization"] = "BEARER " + jwt;
    }
  }

  $.ajax({
    url,
    data,
    headers,
    method,

    context: $form,
    beforeSend: function () {
      // Validation & Loader
      if (!$form.validate()) {
        d("--inavlid");
        return $form.renderValidation();
      } else $form.addLoading();
      // Presubmit to alter data or disable form
      $form.trigger("presubmit");

      // Check for disabled form
      if ($form.data("disabled")) {
        d("--disabled");
        return false;
      }
      $form.data("disabled", true);
      //   d("Submit form " + method.toUpperCase() + "<" + url + ">");
      //   d(data);

      return true;
    },
    complete: function (r) {
      const response = parseJSON(r.responseText);
      $(this).addLoading("success", false);
      $(this).removeData("disabled");
      $(this).removeData("changed");
      $(this).removeAttr("disabled");
      d("rrrrresponse:", response);
      response.data.statusCode = response.status;
      $(this).trigger("complete", response.data);
      // $(this).message({ status: 'info', message: 'Server is complete...' }, false)
      if (!response.data) {
        $(this).message(
          { status: "warning", message: "Server sent back empty response" },
          false
        );
      }
    },
    // error:function(r) {
    //     d('error:',e,res);
    //     $(this).removeLoading();
    //     $(this).removeAttr('disabled');
    //     $(this).trigger('failure', response.data);
    //     return false;
    // }
  });
}
function ajaxSubmitForm(e) {
  
  const $ = jQuery.noConflict();
  e.preventDefault();
  const $form = $(this);
  const headers = {};
  if (!$form.data('public') ) {
    const jwt = getCookie('jwt' );
    if (jwt) {
      d('jwt:', jwt);
      headers["Authorization"] = "BEARER " + jwt;
    }
  }

  // Prepare Data
  const method = $form.attr("method") || "get";
  //   d("method:", method);
  const url = $form.attr("action");
  const enctype = $form.attr("enctype");
  // d('submit form?');
  var data = new FormData();
  if( method == 'put' ) {
    var data = {}
    $form.find(":input").each(function () {
      const name = $(this).attr("name");
      const value = $(this).val();
      data[name] = value;
    })
    var processData = false;
    var contentType = 'application/json';
    var data = JSON.stringify(data);
    d('data:', data);
  } else if (enctype == "multipart/form-data") {
    $form.find(":input").each(function () {
      const name = $(this).attr("name");
      const value = $(this).val();
      if ($(this).attr("type") == "file") {
        if ($(this)[0].files)
          for (var file of $(this)[0].files) {
            d(name, file, file.name);
            data.append(name, file);
          }
      } else if ($(this).attr("type") == "checkbox") {
        d("CHECKBOX!",name,value);
          if( $(this).is(':checked') ) {
            d('checked!',name);
            data.append( name, true );
          }
      } else {
        // d(name,value);
        data.append(name, value);
      }
    });
    var processData = false;
    var contentType = false;
  } else {
    $form.find(":input").each(function () {
      const name = $(this).attr("name");
      const value = $(this).val();
      if ($(this).attr("type") == "checkbox") {
        d("CHECKBOX!", name, value);
        if ($(this).is(':checked')) {
          d('checked!', name);
          data.append(name, true);
        } else {
          data.append(name, false);
        }
      } else {
        // d(name,value);
        data.append(name, value);
      }
    });
    var processData = false;
    var contentType = false;
    // var processData = true;
    // var contentType = "application/x-www-form-urlencoded; charset=UTF-8";
  }


  d('data:',data);
  // Submit
  $.ajax({
    url,
    data,
    headers,
    method,
    processData,
    contentType,
    context: $form,
    beforeSend: function () {
      // Validation & Loader
      if (!$form.validate()) {
        d("--inavlid");
        return $form.renderValidation();
      } else $form.addLoading();
      // Presubmit to alter data or disable form
      $form.trigger("presubmit");

      // Check for disabled form
      if ($form.data("disabled")) {
        d("--disabled");
        return false;
      }
      $form.data("disabled", true);
      //   d("Submit form " + method.toUpperCase() + "<" + url + ">");
      //   d(data);

      return true;
    },
    complete: function (r) {
      const response = parseJSON(r.responseText);
      $(this).addLoading("success", false);
      $(this).removeData("disabled");
      $(this).removeData("changed");
      $(this).removeAttr("disabled");
      // d('response:',response);

      $(this).trigger("complete", response.data);
      // $(this).message({ status: 'info', message: 'Server is complete...' }, false)
      if (!response.data) {
        $(this).message(
          { status: "warning", message: "Server sent back empty response" },
          false
        );
      }
    },
    // error:function(r) {
    //     d('error:',e,res);
    //     $(this).removeLoading();
    //     $(this).removeAttr('disabled');
    //     $(this).trigger('failure', response.data);
    //     return false;
    // }
  });
}
function ajaxCompleteForm(e, data) {
  const $ = jQuery.noConflict();
  if (e.isDefaultPrevented()) {
    d("--default prevented?");
    return;
  }
  $(this).removeLoading();
  if (data) {
    if (data.status && !data.message) data.message = data.status;
    // d('complete', data);
    $(this).removeLoading();
    $(this).processResponseSimple(data);
  }
}
function clearInvalid() {
  const $ = jQuery.noConflict();
  $(this).closest(".form-group").removeClass("invalid");
  $(this).closest(".form-group").removeAttr("invalid");
}
