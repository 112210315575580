const { setLocalData } = require("../utils/localData");
const { getCookie, setCookie } = require('../utils/cookies');
export default (e, data) => {
  const $ = jQuery.noConflict();
  
  if (data.statusCode == 200 || !data.statusCode) {
    setLocalData("user", data.user); 
    const rememberme = $('input[name=rememberme]').is(':checked');
    const expires = rememberme ? 30 : 1;
    // d('cookie expires:',expires,data.jwt);
    setCookie( 'jwt', data.jwt, expires );
    // d('user:',data.user);
    setLocalData("coupons", data.user.favorites );
    $(document).trigger("loggedIn");
  } else {
    setLocalData("user", "");
    setCookie('jwt', '', -1);
    setLocalData("coupons", "");
    $(document).trigger("loggedIn", null);
  }
};
