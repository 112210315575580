const axios = require('axios');
let data = null, promise=null, templatePromise=null;
export const loadData = async() => {
    d("GETTING DATA!");
    if( !data && !promise ) {
        d('-- no data');
        promise = axios.get('/data.json')
    }
    if( promise ) {
        // d('-- resolse promise');
        const res = await promise;
        data = res.data;
        if( typeof data == 'string' ) try {
            data = JSON.parse(data);
        } catch( e ) {
            d('error loading data:',e.message,data);
        }
    }
    // d('data:', data);
    return data;
}

export const loadTemplates = async() => {
    if( !data && !templatePromise ) {
        // d('-- no data');
        templatePromise = axios.get('/templates.json')
    }
    if( templatePromise ) {
        // d('-- resolse promise');
        const res = await templatePromise;
        data = res.data;
        // d('data:',data);
    }
    return data;
}