export default ($) => {
    // Click dropdown top level
    $(document).on('click', 'nav .dropdown-toggle i', function (e) {
        d('ICON:',e.target);
        e.stopPropagation();
    });
    $(document).on('click', 'nav .dropdown-toggle', function (e) {
        d('target:', e.target.tagName);
        // d('click!');
        if (e.target.tagName == 'A') {
            var url = $(this).attr('href');
            d(url);
            // if (url) window.location.href = url;
        }
    });

    // Show Dropdown
    $(document).on('mouseenter', 'nav .dropdown-toggle', function () {
        d('hover!');
        $('nav .dropdown-menu').removeClass('show');
        const $menu = $(this).closest('.dropdown').find('.dropdown-menu');
        d('menu:', $menu);
        $menu.addClass('show');
    });

    // Hide Dropdown
    $(document).on('mouseleave', 'nav .dropdown-menu', function () {
        d('hover!');
        const $menu = $(this).closest('.dropdown').find('.dropdown-menu');
        d('menu:', $menu);
        $menu.removeClass('show');
    });
}