const { getLocalData, setLocalData } = require("../utils/localData");
const { DOM, renderIcon } = require("../ui");
import myAccount from "./loggedIn/myAccount";
export default function (e, data) {
  const $ = jQuery.noConflict();
  // d('data:',data);
  const coupons = getLocalData("coupons") || [];
  const existing = coupons.findIndex((c) => c.id == data.id);
  if (existing > -1) {
    coupons[existing] = data;
    d("exists");
  } else coupons.push(data);
  setLocalData("coupons", coupons);
  // d('set copouns',coupons);
  renderIcon($(e.target).closest(".icons"), data);
  $(e.target).closest(".icons").append('<div class="spinner-border text-primary"></div>');
  myAccount($);
}
