const { getLocalData, setLocalData } = require("../../utils/localData");
const { DOM, replaceArea, updateItems } = require("../../ui");
const updateIcons = require("./updateIcons").default;
const {loadData} = require("../../utils/loadData");

export default async($) => {
  const user = getLocalData("user");
  const coupons = getLocalData("coupons");
  // d("coupons:", coupons);
  updateIcons();
  if (user && $(DOM.userMe).length) {
    d('-- load my account data');
    const searchData = await loadData();
    // Update User Area
    replaceArea(DOM.userMe, "user", { user });
  
    if (coupons && coupons != undefined && coupons != 'undefined' ) {
      // Update Likes
      // d("coupons:", coupons);
      const likes = coupons
        .filter((c) => c.like)
        .map((c) =>
          searchData.items.find((o) => o.id == c.offer && o.type == c.type)
        ).filter(c => c != undefined);
      // d('likes',likes);
      updateItems(DOM.likes, likes, "item");

      // Update Favorites
      const favorites = coupons
        .filter((c) => c.favorite)
        .map((c) =>
          searchData.items.find((o) => o.id == c.offer && o.type == c.type)
      ).filter(c => c != undefined);
      updateItems(DOM.favorites, favorites, "item");
    }
  }
};
