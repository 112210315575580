const {DOM,classes} = require('../../config');
export default e => {
    const $ = jQuery.noConflict();
    var top = $(window).scrollTop();
    // d('top:',top);

    const vw = $(window).height();
    const body = $('body').height();
    const max = body - 100;
    // d('viewport:',vw,'body:',body,'max:',max);
    if (vw > max) return;
    // Stick main header
    if ($("body").hasClass(classes.stickyHeader) && top > 0) $(DOM.header).addClass(classes.fixed);
    else $(DOM.header).removeClass(classes.fixed);

    if ($("body").hasClass(classes.stickySearch) && top > 0) $(DOM.searchArea).addClass(classes.fixed);
    else $(DOM.searchArea).removeClass(classes.fixed);

    // Change nav style
    // if (top > 0) $('.navbar').removeClass('navbar-light').addClass('navbar-dark');
    // else $('.navbar').removeClass('navbar-dark').addClass('navbar-light');

}