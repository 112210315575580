const { DOM, renderIcon } = require("../../ui");
const { getLocalData } = require("../../utils/localData");
export default () => {
  const $ = jQuery.noConflict();
  
  $(DOM.icons).each(function () {
    const coupons = getLocalData("coupons") || [];
    const id = $(this).data("id");
    // d("upser icon");
    // d('coupons:',coupons);
    const coupon = coupons && coupons != 'undefined' ? coupons.find((c) => c && c.offer == id) : null;
    
    renderIcon($(this), coupon);
  });
};
