const {DOM} = require('../../config');
export default $ => {// Magnific Popups
    $(DOM.magnificPopups).each(function () {
        
        if ($(this).attr('href').indexOf('#') == 0) {
            // d("update:", $(this)[0])
            // d('-- trigger!');
            $(this).magnificPopup({
                removalDelay: 500,
                closeBtnInside: true,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = this.st.el.attr('data-effect');
                    }
                },
                midClick: true
            });
        }
    });
}