function setCookie(cname, cvalue, exdays=1) {
    var dd = new Date();
    dd.setTime(dd.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + dd.toUTCString();
    const cookie = cname + "=" + cvalue + ";" + expires + ";path=/";;
    // d('cookie:',cookie);
    document.cookie = cookie;
}
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
module.exports={setCookie,getCookie}