// Dependencies
window.jQuery = window.$ = require("jquery");
window.nunjucks = require("nunjucks");

const { getCookie, setCookie } = require('./utils/cookies');
require("regenerator-runtime/runtime");
require("bootstrap");
require("cd-magnificpopup");
require("select2");
require("./lib/owl-carousel.js");
require("./lib/countdown.js");
// require("./lib/jparallax/js/jquery.parallax.min.js");
// My Scripts

// Nunjucks Env
require("./lib/waf-simple.js");

const { DOM } = require("./ui");
window.d = console.log;

// Load event handlers
const plugins = require("./actions/loggedIn/plugins").default;
const login = require("./actions/dataLoaded/login").default;
const myAccount = require("./actions/loggedIn/myAccount").default;
const updateIcons = require("./actions/loggedIn/updateIcons").default;
const loginComplete = require("./actions/loginComplete").default;
const likeFavoriteComplete = require("./actions/likeFavoriteComplete").default;
const setFixedHeader = require("./utils/setFixedHeader").default;
const switcher = require("./components/switcher").default;
const owlCarousel = require("./components/owlCarousel").default
const search = require("./components/search").default;
const topMenu = require("./actions/loggedIn/topMenu").default;
const templateButton = require("./components/templateButton").default;
const parallax = require('./components/parallax').default;
const { setLocalData } = require("./utils/localData");
const menu = require('./components/menu').default;
const adminBar = require('./components/adminbar').default;
const yoastLoad = require('./actions/loggedIn/yoastLoad').default;
const documentReady = ($) => {
  // Copy url params into form
  var urlParams = new URLSearchParams(window.location.search);
  var entries = urlParams.entries();
  d('entries:', entries);
  for (entry of entries) {
    console.log('entry:', entry);
    var name = entry[0];
    $('input[name=' + name + ']').val(entry[1]);
  }
  
  setTimeout(function () {
    d('checking slider');
    if( $('.owl-item').length ) {
      d('SLIDES:',$('.owl-item').length);
      var merchants = []
      var hasDuplicates = false;
      $('.owl-item').each( function() {
        var expires = $(this).find('h3').text().replace('Expires ','');
        var title = $(this).find('h2').text();
        var merchant = $(this).find('small').first().text();
        var type  = $(this).find('img.deal').length ? 'deal' : 'coupon';
        if( merchants.indexOf(merchant) > -1 ) {
          duplicate = "(DUPLICATE MERCHANT)";
          hasDuplicates = true;
        } else duplicate = "";
        d(duplicate,expires,': "',title.substr(0,25),'" (',type,')',"MERCHANT:",merchant);
      });
      
      if (hasDuplicates) d("---- SLIDER HAS DUPLICATE MERCHANTS -----");
    }
  }, 1000);
  $(document).trigger("loggedIn");
  // Various Plugins
  plugins($);
  
  // Dropdown menu
  menu($);
  
  adminBar($);;
  // Parallax
  // parallax($);
  // Load Data
  
  

  // After Data Loaded
  $(document).on("dataLoaded", function () {
    // Load Data
    // d('-- ready to login!');
    // login($);
  });

  
  // After loggedIn
  $(document).on("loggedIn", function () {
    
    // Various Plugins
    plugins($);

    // Template buttons handler
    templateButton($);

    // Update Like/Favorite Icons 
    myAccount($);

    // Update My Account Page
    updateIcons($);

    // Update top-area
    topMenu($);

    // SEO Analysis
    yoastLoad($);
  });
  login($);
  // Completion of login/register form
  $(document).on("complete", DOM.loginForms, loginComplete);

  // Like & Favorite Icons
  $(document).on("complete", DOM.likeFavorite, likeFavoriteComplete);

  // Fixed Headers
  setFixedHeader($);
  jQuery(window).scroll(setFixedHeader);

  // Various Defferred Event Handlers
  switcher($);

  // Owl Carousel
  owlCarousel($);

  // Search
  d(window.location.href);
  if( window.location.href.indexOf('search') > -1 ) {
    // d('-- load search data');
    search($);
  }
  // FIXES
  $(document).on('click', DOM.search.submit, function (e) {
    
    const keys = {
      dealtype:'/dealtype/',
      holiday:'/holiday/',
      merchant:'/shop/',
      category:'/category/'
    }
    d('submit search form');
    if (window.location.href.indexOf('search') == -1) {
      var filters = [];
      $(DOM.search.form).find(':input').each(function () {
        var id = $(this).attr('name');
        if (id == 'merchant[]') {
          var selected = $(this).val();
          var $option = $(this).find('option[value="'+selected+'"]');
          d('selected:',selected);
          if( window.shops && selected.length == 1 ) {
            var shop_id = parseInt(selected[0],10);
            var shop = window.shops.find( s=>s.id == shop_id );
            d("SHOP:",shop)
            if( shop ) var val = shop.slug;
          }
        } else {
          var val = $(this).val();
        }
        
        if (id == 'pageNum' || id == 's' ) return;
        if (val) {
          if (Array.isArray(val)) {
            if (val.length > 0) filters.push({id, val});
          } else if( val ) {
            filters.push({id,val});
          }
        }
      })
      // d('filters:', filters);
      // d('length:',filters.length);
      if( filters.length == 1 ) {
        const id = filters[0]['id'].replace('[]','');
        const key = keys[id];
        const value = filters[0]['val'];
        // d('id:', id, key, value);
        if( window.location.href.indexOf(value) == -1 ) {
          const url = key+value;
          // d(key, value, url);
          e.preventDefault();
          window.location.href = url;
        }
        
      }
    }
    // return false;
  });

  $(document).on('submit', 'form', function (e) {
    if (e.isDefaultPrevented()) return false;
    if ($(this).data('handler')) return true;


    var data = $(this).find(':input').serialize();
    var url = $(this).attr('action') + '?' + data;
    var method = $(this).attr('method');
    // d($(this)[0]);

    d('url:', url);
    if (method == 'get') window.location.href = url;
  });

  /* Etc
    ======== */
  // Bootstrap carousel
  $(DOM.carousel).carousel();

  // Bootstrap tooltips
  $(DOM.tooltip).tooltip();

  $(DOM.countdown).each(function () {
    var $count = $(this);
    $(this).countdown({
      zeroCallback: function (options) {
        var date = $count.data('countdown');
        var newDate = new Date( Date.parse( date ) );
        newDate.setUTCHours(11,59,59,0);

        $count.attr("data-countdown", newDate.toISOString());
        $count.countdown({
          unixFormat: true,
        });
      },
    });
  });

  $(document).on("click", 'a[href="/logout"],a[href="#logout"]', function (e) {
    e.preventDefault();
    setCookie( 'jwt', '', -1 );
    setLocalData("user", "");
    setLocalData("coupons", "");

    window.location.href = "/";
    return false;
  });

  
  
  $(document).on('submit', '.newsletter-widget form', function(e) {
    
    var email = $(this).find('input').val(); 
    $('a[href="#register-dialog"]').click();
    d('email!');
    $('.dialog-form').find('input[name=email]').val(email);
    $('.dialog-form').find('input[name=newsletter]').prop('checked','checked');
    e.preventDefault();
  }) 

  $(document).on( 'change', 'select.link', function(e) {
    e.preventDefault();
    var href = $(this).val();
    window.location.href = href;
  })

  $(document).on( 'click', '.tooltip-toggle,[href="#"]', function(e) {
    e.preventDefault();
    return false;
  })

  if( $('.shopsearch').length ) {
    $(document).on( 'click', '.form-group.merchants', function() {
      // return false;
      d('shop data:',window.shops);
      if( !window.shops ) return;
      var data = [{value:'',text:'--'}];
      data = [...data, ...$.map(window.shops, function (obj) {
        obj.text = obj.merchant || obj.id;
      
        return obj;
      })];
      
      window.shopData = data;
      $('.shopsearch').select2({data:data})
      $('.shopsearch').select2('open');
      $('.shopsearch').select2('focus');
      
    });
  }
  
  // $(document).on('click', '[data-toggle="collapse"]', function (e) {
  //   var target = $(this).attr('href');
  //   d("TOGGLE",target);
  //   $(target).toggleClass('show');
  //   $(target).siblings().removeClass('show');
  // });
  
  $(document).on('click', 'a[href="#analysis"]', function (e) {
    var analysis = localStorage.getItem('analysis');
    localStorage.setItem('analysis', analysis ? '' : true );
  });
  
  const visible = localStorage.getItem('analysis');
  d('visible:',visible)
  if (visible) {
    d('show!');
    setTimeout( function() {
      $('#analysis').addClass('show');
    },500);
  }
  
};

jQuery(document).ready(documentReady);
