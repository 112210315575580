const {DOM,replaceArea} = require('../ui');
const {getLocalData} = require('../utils/localData');

const templateButtonHandler = e => {
    const user = getLocalData('user');
    const $ = jQuery.noConflict();
    const template = $(e.target).data('template');
    const el = $(e.target).attr('href');
    const data = $(e.target).data();
    replaceArea( el, template, {user,...data} );
}
export default $ => {
    $(document).on( 'click', DOM.templateButton, templateButtonHandler );
}