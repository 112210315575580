const { getLocalData } = require("../../utils/localData");
var loaded = false;
export default $ => {
    const user = getLocalData("user");
    if (user && !loaded ) {
        var my_awesome_script = document.createElement('script');
        var url = '/assets/yoast-admin.bundle.1.0.0-min.js';
        my_awesome_script.setAttribute('src', url);

        document.head.appendChild(my_awesome_script);
        // d('-- add script',url);
        loaded = true;
    }
}