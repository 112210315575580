const { DOM } = require("../ui");
const env = require("../env");
const updateIcons = require('../actions/loggedIn/updateIcons').default;
const {loadData} = require("../utils/loadData");
const { getParameters} = require('../utils/getParameters');
const plugins = require("../actions/loggedIn/plugins").default;
const {numberWithCommas} = require('../utils/numberWithCommas');

const search = e => {
  const $ = jQuery.noConflict();
  if (e) e.preventDefault();
  (async() => {
    const $ = jQuery.noConflict();
    $('#results').addLoading('secondary');
    const searchData = await loadData();
    window.searchData = searchData;
    // d('submit!');
    // d('search data:',searchData);
    
    const items = searchData.items;
    const paginationCount = 12;

    const cats = $("select[name='category[]']").val() || [];
    const shops = $("select[name='merchant[]']").val() || [];
    const dealtypes = $("select[name='dealtype[]']").val() || [];
    const holidays = $("select[name='holiday[]']").val() || [];
    const s = $("input[name=s]").val();
    const expires_before = $("input[name=expires-before]").val();
    const expires_after = $("input[name=expires-after]").val();
    const type = $("input[name=deal-type]:checked").val();
    const pageNum = parseInt($("input[name=pageNum]").val(), 10) || 0;
    const rating = $("input[name=rating]").val() || 0;

    // d('dealtypes:',dealtypes);
    // d('holidays:',holidays);
    
    
    let values = items
    if (s)
      values = values.filter(
        (c) => c.offer.indexOf(s) > -1 || c.merchant.indexOf(s) > -1
      );
    if( dealtypes.length ) values = values.filter( c => dealtypes.indexOf(c.dealtype) > -1 );
    if( rating ) values = values.filter( c=>c.rating>=rating );
    // d('cats:',cats,'shops:',shops,'holidays:',holidays);
    if( cats.length ) values = values.filter(c => cats.indexOf(c.category) > -1 );
    if( shops.length ) values = values.filter( c => shops.indexOf(c.merchant) > -1 ) ; 
    if (holidays.length) values = values.filter( c=> (holidays.indexOf(c.holiday) > -1 || (holidays.indexOf('all') > -1 && c.holiday)) );
    // d('items:',values.length);

    
    if (expires_before) { 
      const time = Date.parse(expires_before);
      // d('before:',time);
      values = values.filter((c) => {
        const expires = Date.parse(c.enddate);
        // d('time:',time,'expires:',expires);
        return expires < time;
      });
    }

    if (expires_after) {
      const time = Date.parse(expires_before);
      // d('before:', time);
      values = values.filter((c) => {
        const expires = Date.parse(c.enddate);
        // d('time:', time, 'expires:', expires);
        return expires > time;
      });
    }

    if (type) {
      // d('type:',type);
      values = values.filter((c) => c.type == type);
    }
    // d("values:", values, values.length);
    const template = searchData.partials["item"];
    // d("template:", template, searchData.partials);
    // d('values:',values.length,values);

    const count = numberWithCommas( values.length );
    //   d("values:", values.length);
    const pageCount = Math.ceil( values.length / paginationCount) || 1;

    const pagination = {
      previous: null,
      urlBase: "/search",
      next:
        pageNum + 1 != pageCount ? "/search/index.html?page=" + (pageNum + 1) : null,
      previous: pageNum > 0 ? "/search/index.html?page=" + (pageNum - 1) : null,
      pages: [],
      pageCount,
      pageNumber: pageNum,
    };
    //   d("pagination:", pagination);
    for (var i = 0; i < pageCount; i++) {
      pagination.pages.push({
        pageNumber: i,
        pageCount,
      });
    }
    const paginationTemplate = searchData.partials["pagination"];
    // d('paginationTemplate:',paginationTemplate,searchData.partials);
    const paginationHtml = env.renderString(paginationTemplate, {
      pagination,
      btnClass: "btn-outline-primary",
      selectedClass: "btn-primary",
      class: "justify-content-center mb-5 search-pagination pagination",
    });
    const heading = $(DOM.search.form).data('heading');
    var html =
      "<h2>" +heading.replace( ':count', count )+"</h2>" 
        +paginationHtml 
      +'<div class="grid grid-3">';

    const start = (pageNum ) * paginationCount;
    // d('start:',start);
    // d('length:',paginationCount);
    values.splice(start, paginationCount).forEach((item) => {
      const data = {
        collections: { ...searchData, partials: undefined },
        item,
      };
      // d(template);
      html += env.renderString(template, data);
    });
  // updateSearchFilters(values);
    const $form = $(DOM.search.form);
    const timeout = $form.data('timeout');
    $('#results').addLoading('secondary');
    
    setTimeout( function() {
      $("#results").html(html + '</div>' + paginationHtml + "</div>");
      updateIcons($);
    }, timeout);
  })();
};

const paginate = (e) => {
  const $ = jQuery.noConflict();
  e.preventDefault();
  var pageNum = $(e.target).data("page");
  $(DOM.search.pageNum).val(pageNum);
  $(DOM.search.form).submit();
  return false;
};

const updateSearchFilters = async(values) => {
  const $ = jQuery.noConflict();
  // d('update search filters');
  const searchData = values ? {items:values} : await loadData();
  
  var current  ={};
  $('.search-filter').each( function() {
    // d($(this)[0],$(this).val());
    var name = $(this).attr('name').replace('[]','');
    current[name] = $(this).val();
  });
  // d('current:',current);
  const collections = {
    merchant:"merchants",
    category: "categories",
    dealtype: "dealtypes",
    holiday: "holidays"
  }
  // d("UPDATE SEARCH FILTERS?");
  // d('data:',searchData);
  if( searchData.items.length ) {
    var data = searchData.items.filter( o => {
      for( var key in current ) {
        let value = current[key]
        // if( key == 'all' ) value = 'Any';
        if( !value.length ) continue;
        // d('filter key:',key,'value:',value);
        if( key == 'merchantid' && o.merchantid != value ) return false;
        if( o[key] && o[key] != value) return false;
      }
      return true;
    })
    // d('data:',data.length);
    d('process search filters');
    $('.search-filter').each( function() {
      var name = $(this).attr('name').replace('[]','');
      
      const collection = collections[name];
      // d('collection:',collection)
      var values = data.map( i => {
        var key = i[name];
        
        if( name == 'merchantid' || name == 'merchant' ) {
          d('key:', name, key)
          const merchant = searchData.merchants.find(m=>m.id==key)
          // d('merchant:',merchant);
          if (merchant ) {
            try {
              return {
                id:merchant.id,
                text:merchant.merchant
              }
            } catch(e) {
              return {};
            }
          }
        } else {
          // d("KEY:",key);
          const label = searchData[collection].find( i => i.key == key );
          if( key == 'all' ) {
            // d("ALL!!!!");
            return {
              id: key,
              text: 'Any'
            }
          } else if (key && label && key != 0) return {
            id: key,
            text: name == 'merchant' ? key : label.label
          }
        }
      }).filter(i=>i)
      let unique = values.filter((v, i) => values.findIndex(vv=>v.id==vv.id) === i)
      // d('unique:',unique);
      try {
        $(this).select2('destroy');
      } catch(e){}
      $(this).select2({data:unique})
      // d(name, unique.length );
    })
    
    // d('data:',data.length);
}
}
export default ($) => {
  $(document).on("submit", DOM.searchForm, search);
  // Owl Carousel

  // owlSliderPagination = owlCarouselSlider.attr('data-pagination');

  // footer always on bottom
  //   var docHeight = $(window).height();
  //   var footerHeight = $("#main-footer").height();
  //   var footerTop = $("#main-footer").position().top + footerHeight;

  //   if (footerTop < docHeight) {
  //     $("#main-footer").css("margin-top", docHeight - footerTop + "px");
  //   }

  const parameters = getParameters();
  for( var key in parameters ){
    const value = parameters[key];
    const $el = $(`[name="${key}"]`);
    $el.val(value);
    $el.trigger('change');
  }
  search();
  // plugins($);
  
  $(document).on("click", DOM.search.pageButton, paginate);
d("LOAD SER FILTERS!");
  updateSearchFilters();
  $(document).on('click', DOM.search.submit, e => {
    $(DOM.search.pageNum).val(0);
  });
  // $(document).on('change','.search-filter', e => {
  //   $(DOM.search.pageNum).val(0);
  //   search(e)
  // });
  
  
};

