function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
function getParameters() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const
        keys = urlParams.keys(),
        values = urlParams.values(),
        entries = urlParams.entries();

    // for (const key of keys) console.log(key);
    // product, color, newuser, size

    // for (const value of values) console.log(value);
    // shirt, blue, , m

    const data = {}
    for (const entry of entries) {
        const k = entry[0];
        const value = entry[1];
        // console.log(`${entry[0]}: ${entry[1]}`);
        if( k.indexOf('[]' ) > -1 ) {
            // k = k.replace('[]','');
            if( !data[k] ) data[k] = [value]
            else data[k].push( value );
        } else {
            data[k] = value;
        }
    }
    return data;
}
module.exports = { getParameterByName, getParameters};