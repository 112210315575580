const { login } = require("../../services/axios");
const { getLocalData, setLocalData } = require("../../utils/localData");
const {getParameterByName} = require('../../utils/getParameters' );
const {getCookie,setCookie} = require('../../utils/cookies');
export default async () => {
  const jwt = getCookie("jwt");
  const $ = jQuery.noConflict();
  // d('-- logging in');

  const access_token = getParameterByName('access_token')
  if (access_token) {
    const id_token = getParameterByName('id_token');
    const provider = window.location.hash.split('?')[0].replace('#','');
    d('provider:', provider, access_token, id_token)
    const authData = await login({ access_token, id_token, provider }).catch(e=>e)
    d('authData:',authData);
    if( authData.status == 400 ) {
      const msgid = authData.data.message[0].messages[0].id
      if (msgid == 'Auth.form.error.email.taken' ) {
        $('header').prepend('<div class="alert alert-danger">Email address already taken</div>')
      }
    }

    if( authData.status == 200 ) {
      const user = authData.data.user;
      const jwt = authData.data.jwt;
      setLocalData("user", user);
      setLocalData("coupons", user.favorites);
      setCookie('jwt',jwt,30);
      d("-- logged in", user);
      $(document).trigger("loggedIn");
    }
    
    

  }

  if (jwt) {
    const axios = await login();  
    
    const res = await axios.get("/users/me").catch((e) => e.response);
    // d('res:',res.data)
    if (res.status == 200 && typeof res.data == 'object' ) {
      const user = res.data;
      setLocalData("user", user);
      // d("-- logged in", res.data);
      $(document).trigger("loggedIn");
      setLocalData("coupons", user.favorites);
    } else {
      setCookie('jwt','',-1);
      setLocalData("user", "");
      setLocalData("coupons", "");
    }
  }
  $(document).trigger("loggedIn");
};

