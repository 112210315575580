const {loadTemplates} = require("./utils/loadData");
export const DOM = require("../config").DOM;
const env = require("./env");
const { getLocalData } = require("./utils/localData");


export const prependArea = async(el, tmpl, context) => {
  const searchData = await loadTemplates();
  const $ = jQuery.noConflict();
  const template = searchData.partials[tmpl];
  // d('context:',tmpl,context);
  const html = env.renderString(template, context);
  // d(html);
  // d($(el)[0],html);
  $(el).before(html);
  return html;
};


export const replaceArea = async(el, tmpl, context) => {
  const searchData = await loadTemplates();
  const $ = jQuery.noConflict();
  const template = searchData.partials[tmpl];
  // d('context:',tmpl,context);
  const html = env.renderString(template, context)
  // d(html);
  // d($(el)[0],html);
  $(el).replaceWith(html);
  return html;
};

export const updateItems = async(el, items, tmpl) => {
  const searchData = await loadTemplates();
  const user = getLocalData("user");
  const $ = jQuery.noConflict();
  if( $(el).find('.notfound').length ) {
    $(el).data('notfound', $(el).find('.notfound') );
  } else if( items.length == 0 ) {
    const $notfound = $(el).data('notfound');
    $(el).find('.notfound').replaceWith($notfound);
  }
  let html = "";
  for (var item of items) {
    const template = searchData.partials[tmpl];
    html += env.renderString(template, { item });
  }
  if (html) $(el).html(html);
};

export const renderIcon = async($el, coupon) => {
  const searchData = await loadTemplates();
  const $ = jQuery.noConflict();
  const item = $el.data();
  // d('type:',type,$el[0],searchData)
  // d(coupon);
  const template = searchData.partials.icons;
  // d('template:',template);
  // const item = searchData.items.find(
  //   (o) => o.id == $el.data("id") && o.type == type
  // );

  const user = getLocalData("user");
  const apiURL = jQuery('body').data('apiurl');
  const html = env.renderString(template, { coupon, item, apiURL, user });
  // d('el:',$el[0],html,item);
  $el.replaceWith(html);
};
